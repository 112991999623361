/* Style the sidebar - fixed full height */
.sidebar {
  background-color: white;
  height: 100%;
  width: 180px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  align-content: space-between;
}

.sidebar-img {
  width: 50%;
  height: auto;
  padding: 5%
}

.sidebar-img:hover {
  background-color: rgb(252, 83, 84);
  color: white;
}

.sidebar-link {
  display: block;
  background-color: gainsboro;
  color: black;
  margin-bottom: 5px;
  padding-left: 20px;
  width: 180px;
  height: 50px;
  font-size: 30px;
  justify-content: center;
}

.sidebar-link:hover {
  background-color: rgb(252, 83, 84);
  color: white;
  text-decoration-line: none;
}

/* Style the main content */
.main {
  height: 100%;
  margin-left: 180px; /* Same as the width of the sidenav */
}

.background {
  background-color: rgb(252, 83, 84);
  height: 100vh;
  padding-bottom: 16px;
}

.avatar {
  width: 20vh;
  height: auto;
  margin: 1%;
  border-style: solid;
  border-color: white;
  border-width: 5px;
  border-radius: 50%;
}

.jumbotron {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 0px;
}

.align-center {
  text-align: center;
}

.logo-base {
  padding-top: 15px;
  font-weight: bolder;
  font-size: 50px; 
  margin: 0; 
  line-height: 70%;
  text-align: right;
}

.logo-pt1 {
  color: gray;
}

.logo-pt2 {
  color: rgb(252, 83, 84);
}

.logo-pt3 {
  color: black;
}

.jumbotron-p {
  font-size: 22px;
}

@media screen and (max-width: 850px) {
  .background {
    height: 100%;
    padding-bottom: 16px;
  }

  .logo-base {
    padding-top: 0px;
    font-size: 25px; 
  }

  .sidebar {
    height: 70px;
    width: 100%;
    position: relative;
  }

  .sidebar-img {
    display: none;
  }

  .sidebar-link {
    height: 100%;
    margin-left: 5px;
    padding-left: 5px;
    padding-top: 11px;
    font-size: 26px;
    width: 100px;
  }

  .nav {
    height: 100%;
  }

  .main {
    margin-left: 0px; /* Same as the width of the sidenav */
  }
}